import React from "react";
import styles from "./Riqueza.module.css";
import { Container, Row, Col } from "react-bootstrap";
import azeitona from "../../Assets/azeitona.png";
import artesanato from "../../Assets/artesanato-na-madeira.png";
import folhaOliveira from "../../Assets/folha-de-oliveira.png";
import essencias from "../../Assets/essencias.png";
import sabonetes from "../../Assets/sabonetes.png";

const Riqueza = () => {
  return (
    <>
      <section className={styles.generalContent} id="sustentabilidade">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <h2 className={styles.tituloRiqueza}>
                OUTRAS RIQUEZAS DAS OLIVEIRAS
              </h2>
              <p className={styles.textoRiqueza}>
                Não há duvidas que o azeite é o protagonista dos produtos
                oriundos da azeitona, no entanto, existe uma gama de diferentes
                produtos que compõe a oferta de nossos Olivicultores. Alguns
                exemplos destas riquezas das Oliveiras que merecem destaque:
              </p>
            </Col>
            <Col xs={12} md={4}>
              <img
                className={styles.imageRiquezas}
                src={azeitona}
                alt="Azeitona Assoolive"
              />
              <p className={styles.subtitleRiqueza}>Azeitonas em conserva</p>
            </Col>
            <Col xs={12} md={4}>
              <img
                className={styles.imageRiquezas}
                src={artesanato}
                alt="Artesanato da Madeira Assoolive"
              />
              <p className={styles.subtitleRiqueza}>
                Artesanato na madeira de Oliveira
              </p>
            </Col>
            <Col xs={12} md={4}>
              <img
                className={styles.imageRiquezas}
                src={folhaOliveira}
                alt="Folha de Oliveira Assoolive"
              />
              <p className={styles.subtitleRiqueza}>
                Folha de Oliveira para chá terapêutico
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={2}></Col>
            <Col xs={12} md={4}>
              <img
                className={styles.imageRiquezasSecondContent}
                src={essencias}
                alt="Essencias Assoolive"
              />
              <p className={styles.subtitleRiqueza}>Essências</p>
            </Col>

            <Col xs={12} md={4}>
              <img
                className={styles.imageRiquezasSecondContent}
                src={sabonetes}
                alt="Sabonetes Assoolive"
              />
              <p className={styles.subtitleRiqueza}>Sabonetes e cosméticos</p>
            </Col>
            <Col xs={12} md={2}></Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Riqueza;
