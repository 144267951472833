import api from "../../services/api";

export const repoSendEmail = (dataEmail) => {
  return new Promise((resolve) => {
    let response = null;
    api
      .post(`/api/email`, dataEmail)
      .then(async (res) => {
        resolve(res.data);
        return res.data;
      })
      .catch((error) => {
        response = error.response.data;
      })
      .finally(() => {
        resolve(response);
        return response;
      });
  });
};
