import React from "react";
import styles from "./Associados.module.css";
import { Container, Row, Col } from "react-bootstrap";
import azeiteRossini from "../../Assets/azeite-rossini.png";
import casaMantiva from "../../Assets/casa-mantiva.png";
import estanciaDoPinheiro from "../../Assets/estancia-do-pinheiro.png";
import gamarra from "../../Assets/gamarra.png";
import oliq from "../../Assets/oliq.png";
import olivaBr from "../../Assets/oliva-br.png";
import olivasVikaz from "../../Assets/olivas-vikaz.png";
import olivaisDeQuelemem from "../../Assets/olivais-de-quelemem.png";
import quintaDoSoalheiro from "../../Assets/quinta-do-soalheiro.png";
import sabia from "../../Assets/sabia.png";
import sanBenedetto from "../../Assets/san-benedetto.png";
import santaClara from "../../Assets/santa-clara.png";
import serraQueChora from "../../Assets/serra-que-chora.png";
import sitioDaPedra from "../../Assets/sitio-da-pedra.png";
import terraSemMal from "../../Assets/terra-sem-mal.png";
import verdeOliva from "../../Assets/verde-oliva.png";
import veroli from '../../Assets/veroli-associado.png'

const Associados = () => {
  return (
    <section className={styles.generalContent} id="associados">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h1 className={styles.tituloAssoolive}>NOSSOS ASSOCIADOS</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={azeiteRossini}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={casaMantiva}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={estanciaDoPinheiro}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={gamarra}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={oliq}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={olivaBr}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={olivasVikaz}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={olivaisDeQuelemem}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={quintaDoSoalheiro}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={sabia}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={sanBenedetto}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={santaClara}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={serraQueChora}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={sitioDaPedra}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={terraSemMal}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={verdeOliva}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.contentAssociados}>
              <img
                src={veroli}
                alt="Associado Assoolive"
                className={styles.logoParceiros}
              />
            </div>
          </Col>
        </Row>
        <Row>
        <Col xs={12} md={12}>
            <h2 className={styles.tituloConteudosDeInteresse}>
              CONTEÚDOS DE INTERESSE
            </h2>
            <p className={styles.legendaConteudosDeInteresse}>
              Confira mais fazendo o download dos arquivos a seguir:
            </p>
        </Col>
        <Col xs={12} md={12}>
          <div className={styles.boxArticle}>
            <h3>PREÇO DO AZEITE DE OLIVA: TIRE AQUI SUAS PRINCIPAIS DÚVIDAS</h3>
            <a href="https://www.olibi.com.br/preco-do-azeite-de-oliva/" target="_blank" rel="noreferrer">https://www.olibi.com.br/preco-do-azeite-de-oliva/</a>
          </div>
        </Col>
        <Col xs={12} md={12}>
          <div className={styles.boxArticle}>
            <h3>Consumo de azeite reduz risco de morrer por doenças do coração, diz estudo</h3>
            <a href="https://olhardigital.com.br/2022/01/11/medicina-e-saude/consumo-de-azeite-reduz-risco-de-morrer-por-doencas-do-coracao-diz-estudo/" target="_blank" rel="noreferrer">https://olhardigital.com.br/2022/01/11/medicina-e-saude/consumo-de-azeite-reduz-risco-de-morrer-por-doencas-do-coracao-diz-estudo/</a>
          </div>
        </Col>
        <Col xs={12} md={12}>
          <div className={styles.boxArticle}>
            <h3>EPAMIG anuncia datas do Azeitech 2022</h3>
            <a href="https://epamig.wordpress.com/2022/01/13/epamig-anuncia-data-do-azeitech-2022/" target="_blank" rel="noreferrer">https://epamig.wordpress.com/2022/01/13/epamig-anuncia-data-do-azeitech-2022/</a>
          </div>
        </Col>
        <Col xs={12} md={12}>
          <div className={styles.boxArticle}>
            <h3>Consumption of Olive Oil and Risk of Total and Cause-Specific Mortality Among U.S. Adults</h3>
            <a href="https://www.jacc.org/doi/10.1016/j.jacc.2021.10.041" target="_blank" rel="noreferrer">https://www.jacc.org/doi/10.1016/j.jacc.2021.10.041</a>
          </div>
        </Col>
        <Col xs={12} md={12}>
          <div className={styles.boxArticle}>
            <h3>Aprenda a escolher o AZEITE DE OLIVA ideal</h3>
            <a href="https://www.youtube.com/watch?v=UFeKbpjQWrg" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=UFeKbpjQWrg</a>
          </div>
        </Col>
        <Col xs={12} md={12}>
          <div className={styles.boxArticle}>
            <h3>Novo podcast da EPAMIG dá dicas para escolha e harmonização de azeites</h3>
            <a href="https://epamig.wordpress.com/2022/01/19/novo-podcast-da-epamig-da-dicas-para-escolha-e-harmonizacao-de-azeites/" target="_blank" rel="noreferrer">https://epamig.wordpress.com/2022/01/19/novo-podcast-da-epamig-da-dicas-para-escolha-e-harmonizacao-de-azeites/</a>
          </div>
        </Col>
        <Col xs={12} md={12}>
          <div className={styles.boxArticle}>
            <h3>Trilhas do Sabor - Ep 55 - Parte 1 - Azeite de Maria da Fé</h3>
            <a href="https://www.youtube.com/watch?v=xIMmrEvquz8&feature=youtu.be" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=xIMmrEvquz8&feature=youtu.be</a>
          </div>
        </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Associados;
