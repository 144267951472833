import React from "react";
import styles from "./Selo.module.css";
import { Container, Row, Col } from "react-bootstrap";
import seloImage from "../../Assets/selo.png";

const Selo = () => {
  return (
    <section className={styles.generalContent} id="selo">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h3 className={styles.tituloSelo}>NOSSO SELO</h3>
            <p className={styles.textoSelo}>O selo Assoolive é a  garantia de origem e de requisitos necessários para que determinados azeites atendam a padrões de qualidade. Para conferir mais detalhes sobre os pré-requisitos e para saber mais sobre o assunto, faça o download do arquivo, <a className={styles.linkSelo} href="https://drive.google.com/file/d/15frF9NiJ0tdEHXrKFK6KcKuzTWxvwOSR/view?usp=sharing" target="_blank" rel="noreferrer">CLIQUE AQUI:</a> </p>
            <img src={seloImage} className={styles.selo} alt="Selo Assoolive"/>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Selo;
