import React from "react";
import styles from "./Olivoturismo.module.css";
import { Container, Row, Col } from "react-bootstrap";

const Olivoturismo = () => {
  return (
    <>
      <section className={styles.generalContent} id="sustentabilidade">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <h2 className={styles.tituloOlivoturismo}>OLIVOTURISMO</h2>
              <p className={styles.textoSustentabilidade}>
                O Oliviturismo vem ocupando um espaço na programação de turistas
                que visitam nossa região. Já são vários Olivicultores que
                recebem em suas propriedades visitantes curiosos por conhecer
                mais do universo da produção das azeitonas, extração do azeite
                e, é claro, degustar! Os turistas terminam o passeio encantados
                com a beleza natural e com este fascinante mundo da
                Olivicultura!
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.contentBanner}></section>
    </>
  );
};

export default Olivoturismo;
