import React, { useState } from "react";
import styles from "./Azeitona.module.css";
import { Container, Row, Col } from "react-bootstrap";
import arbequina from "../../Assets/Arbequina.png";
import koroneiki from "../../Assets/Koroneiki.png";
import grappolo from "../../Assets/Grappolo.jpg";
import arbosana from "../../Assets/Arbosana.png";
import mariaDaFe from "../../Assets/maria-da-fe.png";
import coratina from "../../Assets/Coratina.jpg";

const Azeitona = () => {
  const [sarbequina, setSArbequina] = useState(false);
  const [sKoroneiki, setSKoroneiki] = useState(false);
  const [sGrappolo, setSGrappolo] = useState(false);
  const [sArbosana, setSArbosana] = useState(null);
  const [sMariaDaFe, setSMariaDaFe] = useState(false);
  const [sCoratina, setSCoratina] = useState(false);

  return (
    <section className={styles.generalContent} id="azeitona">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h2 className={styles.tituloAzeitona}>Azeitonas</h2>
            <p className={styles.legendaAzeitona}>
              Existem milhares de variedades de azeitonas e porém somente
              algumas são regularmente produzidas para extração do azeite. Cada
              “terroir” atribui ao azeite características particulares, ou seja,
              uma mesma variedade produzirá azeites diferentes de acordo com sua
              origem. Nos contrafortes da Mantiqueira as principais variedades
              cultivadas são:
            </p>
          </Col>
          <Col xs={12} md={4}>
            <div
              className={
                sarbequina
                  ? "rotate"
                  : !sarbequina
                  ? "desrotate"
                  : styles.content
              }
              onMouseEnter={() => setSArbequina(true)}
              onMouseLeave={() => setSArbequina(false)}
            >
              {sarbequina ? (
                <div className={styles.contentBlue}>
                  <h3 className={styles.titleContentBlue}>Arbequina</h3>
                  <p className={styles.textContentBlue}>
                    Esse tipo de azeitona espanhola é originária da região
                    Arberca, na Catalunha. A partir dela, se produz um azeite
                    fresco de características frutadas com notas de alcachofra e
                    tomate. Bem equilibrado, ele tem sabores mais picantes
                    quando a azeitona é colhida verde e mais doces quando elas
                    estão mais maduras, sua adaptação origina azeites extra
                    virgem especiais no Brasil.
                  </p>
                </div>
              ) : (
                <>
                  <img
                    className={styles.imageContent}
                    src={arbequina}
                    alt="Azeitona Assoolive"
                  />
                  <h2 className={styles.titleImage}>ARBEQUINA</h2>
                </>
              )}
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div
              className={
                sKoroneiki
                  ? "rotate"
                  : !sKoroneiki
                  ? "desrotate"
                  : styles.content
              }
              onMouseEnter={() => setSKoroneiki(true)}
              onMouseLeave={() => setSKoroneiki(false)}
            >
              {sKoroneiki ? (
                <div className={styles.contentBlue}>
                  <h3 className={styles.titleContentBlue}>Koroneiki</h3>
                  <p className={styles.textContentBlue}>
                    Uma das azeitonas gregas mais conhecidas, a Koroneiki é uma
                    espécie que se adaptou bem ao clima e solo brasileiro. O
                    azeite produzido por ela é bem frutado, com notas de maçã
                    verde. Ele também possui uma certa picância e um leve
                    amargor.
                  </p>
                </div>
              ) : (
                <>
                  <img
                    className={styles.imageContent}
                    src={koroneiki}
                    alt="Azeitona Assoolive"
                  />
                  <h2 className={styles.titleImage}>KORONEIKI</h2>
                </>
              )}
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div
              className={
                sGrappolo ? "rotate" : !sGrappolo ? "desrotate" : styles.content
              }
              onMouseEnter={() => setSGrappolo(true)}
              onMouseLeave={() => setSGrappolo(false)}
            >
              {sGrappolo ? (
                <div className={styles.contentBlue}>
                  <h3 className={styles.titleContentBlue}>Grappolo</h3>
                  <p className={styles.textContentBlue}>
                    Para quem busca um cultivar de alta produtividade, esta
                    espécie de origem italiana pode ser uma boa opção. A boa
                    produção de azeitonas por árvore é acompanhada de um bom
                    rendimento em azeite. As sensações transmitidas pelo azeite
                    Grappolo são frutado verde, picante, com notas amargas e
                    sabor marcante
                  </p>
                </div>
              ) : (
                <>
                  <img
                    className={styles.imageContent}
                    src={grappolo}
                    alt="Azeitona Assoolive"
                  />
                  <h2 className={styles.titleImage}>GRAPPOLO</h2>
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <div
              className={
                sArbosana ? "rotate" : !sArbosana ? "desrotate" : styles.content
              }
              onMouseEnter={() => setSArbosana(true)}
              onMouseLeave={() => setSArbosana(false)}
            >
              {sArbosana ? (
                <div className={styles.contentBlue}>
                  <h3 className={styles.titleContentBlue}>Arbosana</h3>
                  <p className={styles.textContentBlue}>
                    Essa azeitona espanhola também se adaptou bem aos solos
                    tropicais do Brasil. O seu azeite é picante e levemente
                    amargo e possui um caráter médio frutado, com notas bem
                    distinguidas de tomates maduros.
                  </p>
                </div>
              ) : (
                <>
                  <img
                    className={styles.imageContent}
                    src={arbosana}
                    alt="Azeitona Assoolive"
                  />
                  <h2 className={styles.titleImage}>ARBOSANA</h2>
                </>
              )}
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div
              className={
                sMariaDaFe
                  ? "rotate"
                  : !sMariaDaFe
                  ? "desrotate"
                  : styles.content
              }
              onMouseEnter={() => setSMariaDaFe(true)}
              onMouseLeave={() => setSMariaDaFe(false)}
            >
              {sMariaDaFe ? (
                <div className={styles.contentBlue}>
                  <h3 className={styles.titleContentBlue}>Maria da Fé</h3>
                  <p className={styles.textContentBlue}>
                    Seu nome é devido a cidade onde foi desenvolvida e
                    registrada, é a única variedade brasileira. Sua
                    característica mais marcante é possuir baixa acidez com
                    olfato frutado traduzido no paladar como casca de nozes e
                    frutos secos. É uma ótima opção para quem gosta de azeites
                    suaves e “doces”, mas também vai muito bem em blends.
                  </p>
                </div>
              ) : (
                <>
                  <img
                    className={styles.imageContent}
                    src={mariaDaFe}
                    alt="Azeitona Assoolive"
                  />
                  <h2 className={styles.titleImage}>MARIA DA FÉ</h2>
                </>
              )}
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div
              className={
                sCoratina ? "rotate" : !sCoratina ? "desrotate" : styles.content
              }
              onMouseEnter={() => setSCoratina(true)}
              onMouseLeave={() => setSCoratina(false)}
            >
              {sCoratina ? (
                <div className={styles.contentBlue} style={{ padding: "1em" }}>
                  <h3 className={styles.titleContentBlue}>Coratina</h3>
                  <p className={styles.textContentBlue}>
                    Cultivo muito maleável, adaptando-se a climas frios e
                    quentes, suportando geadas. Esta espécie de origem italiana
                    produz de maneira precoce aos 3 anos muitas raízes. Seu
                    paladar frutado lembra ervas frescas, porém é picante e um
                    pouco amargo no final. Dado suas características
                    organolépticas é muito utilizado para dar vida a azeites
                    “planos”, com baixas notas das características presentes nos
                    azeites extra virgens: frutado, picância e amargor.
                  </p>
                </div>
              ) : (
                <>
                  <img
                    className={styles.imageContent}
                    src={coratina}
                    alt="Azeitona Assoolive"
                  />
                  <h2 className={styles.titleImage}>CORATINA</h2>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Azeitona;
