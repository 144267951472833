import React from "react";
import styles from "./SerraDaMantiqueira.module.css";


const SerraDaMantiqueira = () => {
  return (
    <section className={styles.generalContent} id="serradamantiqueira">
      <div className={styles.contentText}>
        <h2 className={styles.titleSerraDaMantiqueira}>Serra da Mantiqueira</h2>
        <p className={styles.textSerraDaMantiqueira}>
          Bela Geografia entre picos, águas e florestas, a Serra da Mantiqueira é
          uma das mais belas cadeias de montanha no Brasil. Na divisa entre
          Minas Gerais, Rio de Janeiro e São Paulo, a Mantiqueira abriga belas
          cidades turísticas e atrativos naturais incríveis. São mais de 500 km
          de cachoeiras, rios e florestas.
        </p>
      </div>
    </section>
  );
};

export default SerraDaMantiqueira;
