import React from "react";
import styles from "./Valores.module.css";
import { Container, Row, Col } from "react-bootstrap";
import missao from "../../Assets/missao.png";
import visao from "../../Assets/visao.png";
import valores from "../../Assets/valores.png";

const Valores = () => {
  return (
    <section className={styles.generalContent} id="valores">
      <Container>
        <Row>
          <Col xs={12} md={4}>
              <img className={styles.imagemValores} src={missao} alt="Missão Assoolive"/>
              <h2 className={styles.tituloValores}>Missão</h2>
              <p className={styles.textoValores}>Representar de forma colaborativa os produtores da região, contribuindo para o reconhecimento e fortalecimento da olivicultura da região.</p>
          </Col>

          <Col xs={12} md={4}>
              <img className={styles.imagemValores} src={visao} alt="Visão Assoolive"/>
              <h2 className={styles.tituloValores}>Visão</h2>
              <p className={styles.textoValores}>Trabalhar para que a qualidade do azeite produzido na região seja reconhecida nacionalmente pelas suas características únicas como frescor, sabor e aroma,  além do diferencial de ser produzido artesanalmente e de forma sustentável por produtores engajados e conscientes.</p>
          </Col>

          <Col xs={12} md={4}>
              <img className={styles.imagemValores} src={valores} alt="Valores Assoolive"/>
              <h2 className={styles.tituloValores}>Valores</h2>
              <p className={styles.textoValores}>Rigoroso controle da qualidade por meio de produções artesanais, transparência, sustentabilidade, responsabilidade social e paixão pelo plantio e cultivo. </p>
          </Col>
          
        </Row>
      </Container>
    </section>
  );
};

export default Valores;
