import {  sendEmail  } from "../Services/Email";


export const doSendEmail = async (dispatch, dataEmail) => {
  try {
    return sendEmail(dataEmail);
  } catch (error) {
    return dispatch({
      type: "REQUEST_ERROR",
      payload: error,
    });
  }
};