import PropTypes from "prop-types";
import React, { createContext, useContext, useReducer } from "react";

const initialState = {
  user: null,
  allNotifications: null,
  message: { message: null, type: null },
  token: "",
  openNotification: false,
  notificationProperties: { message: null, type: null },
};

export const Store = createContext(initialState);

export const useAppContext = () => useContext(Store);

export function reducer(state, action) {
  switch (action.type) {
    case "USER":
      return {
        ...state,
        user: action.payload,
      };
    case "REQUEST_ERROR": {
      return {
        ...state,
        message: { message: action.payload, type: "error" },
      };
    }
    case "OPEN_NOTIFICATION": {
      return {
        ...state,
        notificationProperties: { ...action.payload },
        openNotification: true,
      };
    }
    case "CLOSE_NOTIFICATION": {
      return {
        ...state,
        openNotification: false,
      };
    }
    default:
      return { ...state };
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  const { children } = props;
  return <Store.Provider value={value}>{children}</Store.Provider>;
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
