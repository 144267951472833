import React from "react";
import styles from "./Qualidade.module.css";

const Qualidade = () => {
  return (
    <>
      <section
        className={styles.contentProcessoArtesanal}
        id="processo artesanal"
      >
        <h2 className={styles.tituloQualidade}>Processo Artesanal</h2>
      </section>
      <section className={styles.contentFrescor} id="frescor">
        <h2 className={styles.tituloQualidade}>Frescor</h2>
      </section>
      <section className={styles.contentSustentavel} id="sustentavel">
        <h2 className={styles.tituloQualidade}>Sustentável</h2>
      </section>
    </>
  );
};

export default Qualidade;
