import React from "react";
import styles from "./OAzeite.module.css";
import { Container, Row, Col } from "react-bootstrap";
import Azeite from "../../Assets/oazeite.png";

const OAzeite = () => {
  return (
    <section id="asoolive">
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <img src={Azeite} alt="Azeite Assoolive" />
          </Col>
          <Col xs={12} md={6}>
            <h1 className={styles.tituloAssoolive}>O AZEITE</h1>
            <p className={styles.textoAssoolive}>
              O azeite é um produto milenar, que foi difundido no mundo pelos
              europeus. Apesar da tradição, o azeite de maior qualidade que
              conhecemos hoje, como o azeite extra virgem, é relativamente novo.
              A extração do fruto ainda verde e a técnica de produção
              específica, garantem o frescor e a pureza deste produto.
            </p>
            <p className={styles.textoAssoolive}>
              A primeira extração de azeite extravirgem genuinamente brasileira
              ocorreu no início de 2008, na pequena Maria da Fé, município
              localizado na região da Mantiqueira. A ação pioneira foi resultado
              de trabalhos da Empresa de Pesquisa Agropecuária de Minas Gerais
              (Epamig). Mais de uma década depois, o mercado de azeites nacionais cresce
              cada vez mais, sobretudo na região, onde estão instaladas diversas
              agroindústrias de azeite e cerca de 200 produtores com mais de
              três mil hectares de oliveiras plantadas.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OAzeite;
