import React from "react";
import styles from "./Sustentabilidade.module.css";
import { Container, Row, Col } from "react-bootstrap";

const Sustentabilidade = () => {
  return (
    <section className={styles.generalContent} id="sustentabilidade">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h2 className={styles.tituloSustentabilidade}>
              SOBRE A REGIÃO E SUSTENTABILIDADE
            </h2>
            <p className={styles.textoSustentabilidade}>
              Características específicas sobre o terroir: índice de chuva,
              altitude e temperatura. A região da serra da Mantiqueira tem
              altitudes médias de 1200 a 2800 metros. As precipitações pluviais anuais variam entre
              1.000 a 2.000 mm na serra e entre 1.000 a 1.600 mm no vale.
            </p>

            <p className={styles.textoSustentabilidade}>
              Devido à altitude, o inverno na serra da Mantiqueira tem
              temperaturas baixas, com a ocorrência da névoa no começo da manhã
              e geada frequentes, dando à paisagem a aparência das regiões de
              clima frio. É comum os termômetros registrarem temperaturas que
              chegam perto de 0°C ou menos, sendo que a menor temperatura
              registrada numa cidade da serra foi de -8,4°C em Maria da Fé. O
              clima serrano apresenta invernos frios e verões brandos, com
              temperaturas médias anuais variando de 18 a 19ºC e umidade
              relativa do ar maior que 70%.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Sustentabilidade;
