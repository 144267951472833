import React, { useState } from "react";
import { AppBar, IconButton, List, SwipeableDrawer } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import styles from "./Header.module.css";
import { Link } from "react-router-dom";
import Logo from "../../Assets/logo.png";
import MenuMobile from "./MenuMobile";
// import Facebook from "../../Assets/facebookBlue.png";
// import Instagram from "../../Assets/instagramBlue.png";

const Header = () => {
  const [menuMobile, setMenuMobile] = useState(false);

  function mobileMenuOpen() {
    setMenuMobile(true);
  }
  function mobileMenuClose() {
    setMenuMobile(false);
  }
  return (
    <header>
      <div className="container">
        <div className={styles.header}>
          <Link className={styles.logo} to="/" aria-label="Pro Radar - Home">
            <img
              src={Logo}
              width={
                document.documentElement.scrollWidth > 1 &&
                document.documentElement.scrollWidth < 768
                  ? "150"
                  : "300"
              }
              height={
                document.documentElement.scrollWidth > 1 &&
                document.documentElement.scrollWidth < 768
                  ? "37"
                  : "75"
              }
              alt="Logo Pro Radar"
            />
          </Link>
          <div className={styles.menu}>
            <ul>
              <li>
                <a href="/">HOME</a>
              </li>
              <li>
                <Link to="/sobre">SOBRE</Link>
              </li>
              <li>
                <a href="/rede">REDE ASSOOLIVE</a>
              </li>
            </ul>
          </div>
          <div className={styles.iconContainer}>
            <IconButton
              onClick={mobileMenuOpen}
              className={styles.iconButton}
              color="inherit"
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
          </div>
          <SwipeableDrawer
            anchor="left"
            open={menuMobile}
            onClose={mobileMenuClose}
            onOpen={mobileMenuOpen}
          >
            <AppBar title="Menu" />
            <List>
              <MenuMobile />
            </List>
          </SwipeableDrawer>
        </div>
      </div>
    </header>
  );
};

export default Header;
