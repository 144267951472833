import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from "../Pages/Home/Home";
import RedeAssoolive from "../Pages/RedeAssoolive/RedeAssoolive";
import Sobre from "../Pages/Sobre/Sobre";
import { StoreProvider } from "../Infrastructure/Store/Store";

function Rotas() {
  return (
    <div>
      <StoreProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sobre" element={<Sobre />} />
            <Route path="/rede" element={<RedeAssoolive />}/>
          </Routes>
        </BrowserRouter>
      </StoreProvider>
    </div>
  );
}

export default Rotas;
