import React from "react";
import styles from "./Footer.module.css";
import { Container, Row, Col } from "react-bootstrap";
import Logo from "../../Assets/assoolive-rodape.png";
import Facebook from "../../Assets/facebook.png";
import Instagram from "../../Assets/instagram.png";

const Footer = () => {
  return (
    <section className={styles.generalContent}>
      <Container>
        <Row>
          <Col xs={12} md={4}>
            <div className={styles.redesSociais}>
              <h4>Redes Sociais</h4>
              <div className={styles.logosRedesSociais}>
                <a href="https://www.instagram.com/assoolive_brasil/" target="_blank" rel="noreferrer"><img src={Instagram} alt="Instagram Asoolive" /></a>
                <a href="https://www.facebook.com/Assoolive-Brasil-105704695350517" target="_blank" rel="noreferrer"><img src={Facebook} alt="Facebook Asoolive" /></a>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className={styles.assoolive}>
              <img
                src={Logo}
                className={styles.logoRadar}
                alt="Logo Pro Radar"
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className={styles.redesSociais}>
              <h4>Fale Conosco</h4>
              <h5>assoolive@gmail.com</h5>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
