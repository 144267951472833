import React from "react";
import Header from "../../Components/Header/Header";
import Parceiros from "../../Components/Parceiros/Parceiros";
import Associados from "../../Components/Associados/Associados";
import Footer  from "../../Components/Footer/Footer";

const RedeAssoolive = () => {
  return (
    <>
      <Header />
        <Associados/>
        <Parceiros/> 
      <Footer/>
    </>
  );
};

export default RedeAssoolive;
