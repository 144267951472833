import React from "react";
import Header from "../../Components/Header/Header";
import ApresentacaoSobre from "../../Components/ApresentacaoSobre/ApresentacaoSobre";
import Assoolive from "../../Components/Assoolive/Assoolive";
import Valores from "../../Components/Valores/Valores";
import OAzeite from "../../Components/OAzeite/OAzeite";
import Azeitona from "../../Components/Azeitona/Azeitona";
import NotasPercebidas from "../../Components/NotasPercebidas/NotasPercebidas";
import Qualidade from "../../Components/Qualidade/Qualidade";
import Sustentabilidade from "../../Components/Sustentabilidade/Sustentabilidade";
import SerraDaMantiqueira from "../../Components/SerraDaMantiqueira/SerraDaMantiqueira";
import ONossoAzeite from "../../Components/ONossoAzeite/ONossoAzeite";
import OlivoTurismo from "../../Components/Olivoturismo/Olivoturismo";
import Riqueza from "../../Components/Riqueza/Riqueza";
import Footer  from "../../Components/Footer/Footer";

const Sobre = () => {
  return (
    <>
      <Header />
        <ApresentacaoSobre/>
        <Assoolive/>
        <Valores/>
        <OAzeite/>
        <Azeitona/>
        <NotasPercebidas/>
        <Qualidade/>
        <Sustentabilidade/>
        <SerraDaMantiqueira/>
        <ONossoAzeite/>
        <OlivoTurismo/>
        <Riqueza/>
      <Footer/>
    </>
  );
};

export default Sobre;
