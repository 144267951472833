import React from "react";
import styles from "./Assoolive.module.css";
import { Container, Row, Col } from "react-bootstrap";
import AssooliveImage from "../../Assets/assoolive.png";

const Assoolive = () => {
  return (
    <section id="asoolive">
      <Container fluid>
        <Row>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={5}>
            <h1 className={styles.tituloAssoolive}>A ASSOOLIVE</h1>
            <p className={styles.textoAssoolive}>Associação dos Olivicultores dos Contrafortes da Mantiqueira a ASSOOLIVE foi criada em 2009 com o objetivo de fortalecer, unir e  representar os produtores do segmento da olivicultura da região dos contrafortes¹ da Mantiqueira. Além disso, visa contribuir fomentando a racionalização das atividades agropecuárias e melhorando as condições de vida de seus integrantes. Se preocupa também em dar ênfase na divulgação de matérias relacionadas a técnicas de produção e manejo, mercado e preços, melhoria de qualidade e da produtividade no setor da olivicultura, a fim de promover a troca de experiência e conhecimento entre os produtores. Temos orgulho de fazer parte da construção da história do azeite no Brasil, o mais rico produto funcional que a natureza nos oferece através do plantio. Com muita paixão, responsabilidade e rigor com a qualidade, queremos que o azeite dos Contrafortes da Mantiqueira seja reconhecido e apreciado nacionalmente por suas características únicas e especiais.</p>
            <p className={styles.textoAssooliveRodape}>1- Contrafortes: Em geologia e geografia, refere-se às ramificações laterais, normalmente localizadas no lado oposto àquele onde se encontra a porção mais íngreme de uma cadeia de montanhas.</p>
          </Col>
          <Col xs={12} md={6}>
            <img src={AssooliveImage} alt="Assoolive"/>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Assoolive;
