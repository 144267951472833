import React from "react";
import styles from "./Apresentacao.module.css";
import { Container, Row, Col } from "react-bootstrap";

const Apresentacao = () => {
  return (
    <section className={styles.generalContent} id="apresentacao">
      <Container>
        <Row>
          <Col xs={12} md={7}>
            <h1 className={styles.tituloApresentacao}>Associação dos Olivicultores
            dos Contrafortes da Mantiqueira</h1>
          </Col>
          <Col xs={12} md={5}></Col>
          <Col xs={12} md={6}>
            <h2 className={styles.subtituloApresentacao}>Qualidade, frescor e sustentabilidade.</h2>
          </Col>
          <Col xs={12} md={6}></Col>
        </Row>
      </Container>
    </section>
  );
};

export default Apresentacao;
