import React from "react";
import styles from "./Parceiros.module.css";
import { Container, Row, Col } from "react-bootstrap";
import ufmg from "../../Assets/ufmg.png";
import sebrae from "../../Assets/sebrae.png";
import embrapa from "../../Assets/embrapa.png";
import epamig from "../../Assets/epamig.png";
import mariadafe from "../../Assets/mariadafe.png";

const Parceiros = () => {
  return (
    <section className={styles.generalContent} id="asoolive">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h1 className={styles.tituloAssoolive}>NOSSOS PARCEIROS</h1>
          </Col>
          </Row>
          <Row>
          <Col xs={12} md={3}>
            <img className={styles.imageParceiros} src={epamig} alt= "Parceiro Assoolive"/>
          </Col>
          <Col xs={12} md={2}>
            <img className={styles.imageParceiros} src={ufmg} alt= "Parceiro Assoolive"/>
          </Col>
          <Col xs={12} md={3}>
            <img className={styles.imageParceiros} src={sebrae} alt= "Parceiro Assoolive"/>
          </Col>
          <Col xs={12} md={2}>
            <img className={styles.imageParceiros} src={embrapa} alt= "Parceiro Assoolive"/>
          </Col>
          <Col xs={12} md={2}>
            <img className={styles.imageParceiros} src={mariadafe} alt= "Parceiro Assoolive"/>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Parceiros;
