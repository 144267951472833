import React from "react";
import styles from "./NotasPercebidas.module.css";
import { Container, Row, Col } from "react-bootstrap";
import cafeverde from "../../Assets/cafeverde.png";
import cacau from "../../Assets/cacau.png";
import maracuja from "../../Assets/maracuja.png";

const NotasPercebidas = () => {
  return (
    <section className={styles.generalContent} id="notaspercebidas">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h2 className={styles.tituloNotasPercebidas}>
              Notas percebidas no azeite da região
            </h2>
            <p className={styles.legendaNotasPercebidas}>
              "Depoimento de Ana Beloto, azeitóloga."
            </p>
          </Col>
          <Col xs={12} md={4}>
            <img className={styles.qualidadeCafe} src={cafeverde} alt="Café Verde Assoolive" />
            <h2 className={styles.subTituloNotasPercebidas}>
              CAFÉ VERDE
            </h2>
          </Col>
          <Col xs={12} md={4}>
            <img className={styles.qualidadeCafe} src={cacau} alt="Cacau Assoolive" />
            <h2 className={styles.subTituloNotasPercebidas}>
              CACAU
            </h2>
          </Col>
          <Col xs={12} md={4}>
            <img className={styles.qualidadeCafe} src={maracuja} alt="Maracujá Assoolive" />
            <h2 className={styles.subTituloNotasPercebidas}>
              MARACUJÁ
            </h2>
          </Col>
          <Col xs={12} md={12}>
            <div className={styles.rodapeQualidade}>
              Leia mais: Azeites mineiros ganham destaque internacional. Publicado em:  <a className={styles.linkNoticia} href="https://diariodocomercio.com.br/agronegocio/azeites-mineiros-ganham-destaque-internacional" target="_blank" rel="noreferrer"> https://diariodocomercio.com.br/agronegocio/azeites-mineiros-ganham-destaque-internacional</a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NotasPercebidas;
