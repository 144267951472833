import React from "react";
import styles from "./ApresentacaoSobre.module.css";

const ApresentacaoSobre = () => {
  return (
    <section className={styles.generalContent} id="apresentacao">
    </section>
  );
};

export default ApresentacaoSobre;
