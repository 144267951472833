import React from "react";
import Header from "../../Components/Header/Header";
import Contato from "../../Components/Contato/Contato";
import Apresentacao from "../../Components/Apresentacao/Apresentacao";
import Assoolive from "../../Components/Assoolive/Assoolive";
import Selo from "../../Components/Selo/Selo";
import Parceiros from "../../Components/Parceiros/Parceiros";
import Footer  from "../../Components/Footer/Footer";

const Home = () => {
  return (
    <>
      <Header />
        <Apresentacao/>
        <Assoolive/>
        <Selo/>
        <Contato/>
        <Parceiros/> 
      <Footer/>
    </>
  );
};

export default Home;
