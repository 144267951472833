import React, { useState } from "react";
import styles from "./Contato.module.css";
import { Container, Row, Col } from "react-bootstrap";
import imageContact from "../../Assets/banner-contato.png";
import { doSendEmail } from "../../Infrastructure/Actions/Email";
import { Store } from "../../Infrastructure/Store/Store";

const Contato = () => {
  const { state, dispatch } = React.useContext(Store);
  const [nome, setNome] = useState();
  const [email, setEmail] = useState();
  const [telefone, setTelefone] = useState();
  const [celular, setCelular] = useState();
  const [empresa, setEmpresa] = useState();
  const [assunto, setAssunto] = useState();
  const [mensagem, setMensagem] = useState();

  const handleSendEmail = () => {
    const dataEmail = {
      nome: nome,
      email: email,
      telefone: telefone,
      celular: celular,
      empresa: empresa,
      assunto: assunto,
      mensagem: mensagem,
    };
    doSendEmail(dispatch, dataEmail)
      .then((res) => {
        console.log(res, "resposta api");
        alert(res.message);
        setNome("");
        setEmail("");
        setTelefone("");
        setCelular("");
        setEmpresa("");
        setAssunto("");
        setMensagem("");
        // setOpenSnackbar(false)
      })
      .catch((error) => {
        alert("Erro ao enviar mensagem");
        setNome("");
        setEmail("");
        setTelefone("");
        setCelular("");
        setEmpresa("");
        setAssunto("");
        setMensagem("");
      });
  };

  return (
    <section className={styles.generalContent} id="contato">
      <Container fluid>
        <Row>
          <Col xs={12} md={12}>
            <h1 className={styles.titleForm}>FORMULÁRIO DE CONTATO</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <form
              className={styles.containerFormContact}
              onSubmit={handleSendEmail}
            >
              <h2 className={styles.subTitleForm}>Dados Pessoais</h2>
              <div className={styles.oneLine}>
                <input
                  type="text"
                  name="nome"
                  required="required"
                  onChange={({ target }) => setNome(target.value)}
                  value={nome}
                  className={styles.inputForm}
                  placeholder={"Nome"}
                />
              </div>

              <div className={styles.twoLines}>
                <input
                  type="text"
                  name="telefone"
                  required="required"
                  onChange={({ target }) => setTelefone(target.value)}
                  value={telefone}
                  className={styles.inputForm}
                  placeholder={"Telefone"}
                  style={{ marginRight: "1em" }}
                />
                <input
                  type="text"
                  name="celular"
                  required="required"
                  onChange={({ target }) => setCelular(target.value)}
                  value={celular}
                  className={styles.inputForm}
                  placeholder={"Celular"}
                />
              </div>
              <div className={styles.oneLine}>
                <input
                  type="text"
                  name="empresa"
                  required="required"
                  onChange={({ target }) => setEmpresa(target.value)}
                  value={empresa}
                  className={styles.inputForm}
                  placeholder={"Empresa"}
                />
              </div>

              <div className={styles.oneLine}>
                <input
                  type="text"
                  name="email"
                  required="required"
                  onChange={({ target }) => setEmail(target.value)}
                  value={email}
                  className={styles.inputForm}
                  placeholder={"Email"}
                  style={{ marginTop: "2em" }}
                />
              </div>
              <div className={styles.oneLine}>
                <select
                  name="select"
                  value={assunto}
                  onChange={({ target }) => setAssunto(target.value)}
                  className={styles.selectForm}
                >
                  <option value="valor0" selected>
                    Escolha o assunto
                  </option>
                  <option value="Tornar-se Associado">
                    Tornar-se Associado
                  </option>
                  <option value="vaInformaçõeslor2">Informações</option>
                  <option value="Onde comprar">Onde comprar</option>
                  <option value="Selo">Selo</option>
                  <option value="Outro">Outro</option>
                </select>
              </div>

              <div className={styles.oneLine}>
                <textarea
                  value={mensagem}
                  onChange={({ target }) => setMensagem(target.value)}
                  className={styles.textAreaForm}
                  placeholder={"Mensagem"}
                ></textarea>
              </div>

              <div className={styles.lineRadioButton}>
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="HTML"
                  className={styles.inputRadio}
                />
                 {" "}
                <label for="html" className={styles.textCheckBox}>
                  Desejo receber informativos em meu e-mail
                </label>
                <br />
              </div>
              <button className={styles.buttonForm}>Enviar</button>
            </form>
          </Col>

          <Col xs={12} md={6}>
            <img
              className={styles.imagemContato}
              src={imageContact}
              alt="Contato Asoolive"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contato;
