import React from "react";
import styles from "./ONossoAzeite.module.css";
import { Container, Row, Col } from "react-bootstrap";
import Azeite from "../../Assets/o-nosso-azeite.jpg";

const ONossoAzeite = () => {
  return (
    <>
      <section className={styles.separador}></section>
      <Container fluid>
        <Row>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={5}>
            <h1 className={styles.tituloAssoolive}>O NOSSO AZEITE</h1>
            <p className={styles.textoAssoolive}>
              Os produtos da Serra da Mantiqueira, já há alguns anos, têm
              conquistado paladares e despertado o interesse dos consumidores
              pelo frescor, sabor e notas específicas. Muitos especialistas
              associam essas qualidades ao terroir propiciado pela serra da
              Mantiqueira: As montanhas, o ar e a água pura fazem toda a
              diferença. O azeite chega fresco ao consumidor. O clima das
              montanhas e a diversidade da vegetação impactam positivamente na
              complexidade de aromas e sabores dos azeites da Mantiqueira, cada
              vez mais valorizados no Brasil e no exterior. Ao degustar esses
              azeites, percebe-se notas de frutas tropicais como maracujá, cacau
              e café verde. Uma combinação de fatores que resulta em um produto
              de excelência e extrema qualidade
            </p>
            <p className={styles.textoAssoolive}>
              Um grande diferencial dos produtores da Serra Mantiqueira é o fato
              de que a maioria deles possui terras de extensão reduzida o que
              influencia em uma produção mais rigorosa, cuidadosa e comprometida
              com a qualidade. As produções, por mais que mecanizadas, têm um
              compromisso com o artesanal e com a sustentabilidade, garantindo
              assim um ciclo saudável e responsável de produção.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <img className={styles.imagemNossoAzeite} src={Azeite} alt="Azeite Assoolive" />
          </Col>
        </Row>
      </Container>
      <section className={styles.separador}></section>
    </>
  );
};

export default ONossoAzeite;
