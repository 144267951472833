import React from "react";
import {
  ListItem,
  Link,
  ListItemText,
  withStyles,
  List,
  Divider,
} from "@material-ui/core";

import styles from "./MenuMobile.module.css";

const MenuMobile = () => {
  const renderMenu = () => {
    return (
      <>
        <div className={styles.containerMenu}>
          <Link href={"/"} className={styles.link}>
            <ListItem button>
              <ListItemText primary="HOME" />
            </ListItem>
          </Link>
          <Link href={"/sobre"} className={styles.link}>
            <ListItem button>
              <ListItemText primary="SOBRE" />
            </ListItem>
          </Link>
          <Link href={"/rede"} className={styles.link}>
            <ListItem button>
              <ListItemText primary="REDE ASSOOLIVE" />
            </ListItem>
          </Link>
        </div>
        <Divider />
      </>
    );
  };

  return (
    <React.Fragment>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={styles.menuLateral}
      >
        {renderMenu()}
      </List>
    </React.Fragment>
  );
};

export default withStyles(styles)(MenuMobile);
